const appConf = {
  prod: {
    server: 'https://partner.revieve.com/',
    apiVersion: '3',
  },
  test: {
    server: 'https://partner-test.revieve.com/',
    apiVersion: '3',
  },
  cn: {
    server: 'https://partner.revieve.cn/',
    apiVersion: '3',
  },
  CITest: {
    server: 'https://partner-test.revieve.com/',
    apiVersion: '3',
  },
};

export const apiVersionsAvailable = ['2', '3'];

export function getServerUrl(testing = false, environment = 'test') {
  if (testing || environment === 'test') {
    return appConf.test.server;
  } else {
    // environment can have the URL of the backend server
    return appConf[environment] ? appConf[environment].server : environment;
  }
}

export function getApiVersion(testing = false, environment = 'test') {
  if (testing || environment === 'test' || process.env.NODE_ENV === 'development') {
    environment = 'test';
  }
  return appConf[environment]?.apiVersion ?? '3';
}

export function getUaAccount(testing = false, environment = 'test') {
  if (testing || environment === 'test' || process.env.NODE_ENV === 'development') {
    environment = 'test';
  }
  return appConf[environment]?.uaAccount;
}

export const highlightColor = 'rgb(0,0,0)';

export const defaultStrength = '0.7';

export const makeupMeasures = {
  faceShape: {
    1: 'Diamond',
    2: 'Heart',
    3: 'Long',
    4: 'Oval',
    5: 'Round',
    6: 'Square',
  },
  eyeColor: {
    1: 'Brown',
    2: 'Blue',
    3: 'Green',
  },
  skinUndertone: {
    '-1': 'Cool',
    0: 'Neutral',
    1: 'Warm',
  },
};

export const hairMeasures = {
  hairType: {
    0: 'coily',
    1: 'cornroll',
    2: 'curly',
    3: 'straight',
    4: 'wavy',
  },

  hairFrizziness: {
    0: 'low_frizz',
    1: 'high_frizz',
  },
};
