import initializeLiveAR, { PIXI, analyzeImage, loadLibraryFiles, scaleImage } from 'revieve-livear-module';
import { apiVersionsAvailable, getApiVersion, getUaAccount } from './appConf';
import Analytics from './modules/Analytics';
import RevieveAR from './modules/RevieveAR';
import RevieveCV from './modules/RevieveCV';
import RevievePR from './modules/RevievePR';

if (document.currentScript && document.currentScript.src) {
  const { href } = new URL(document.currentScript.src);
  const path = href.substring(0, href.lastIndexOf('/'));
  // eslint-disable-next-line
  __webpack_public_path__ = path + __webpack_public_path__;
}

/**
 * Revieve SDK class.
 */
class RevieveSDK {
  /**
   * Creates an instance of RevieveSDK.
   * You can access to CV, AR or PR modules throught CV, AR, PR properties.
   *
   * @constructor
   * @param {String} partnerID - PartnerID assigned to your organization by Revieve
   * @param {Boolean} testing - true if you want to work in test enviroment
   * @param {String} environment - if you set testing to false, you can specify
   * the environment you want to connect (test, prod or cn)
   * @param {Boolean} disableUsageAnalytics - flag for disable telemetry. Defaults to false
   * @param {Boolean} avoidCookies- flag for disable cookies for analytics. Defaults to false
   */
  constructor(partnerID, testing = true, environment = 'test', disableUsageAnalytics = false, avoidCookies = false) {
    if (!partnerID || typeof partnerID === 'undefined' || typeof partnerID !== 'string') {
      console.error('partnerID is mandatory and it must be string typed');
      return Object.create(null);
    }
    this._state = { partnerID, testing, environment: testing ? 'test' : environment };
    this._state.apiVersion = getApiVersion(testing, environment);
    RevieveSDK.masks = RevieveAR.masks[getApiVersion(testing, environment)];
    this._state.configuration = {};
    this.analytics = new Analytics();
    // Revieve Analytics account
    if (!disableUsageAnalytics) {
      const uaAccount = getUaAccount(testing, environment);
      if (uaAccount) {
        this.analytics.addTrackers([{ provider: 'ga', prefix: 'RevieveSDK', account: uaAccount }], avoidCookies);
        this.analytics.setCustomDimensionToGaTracker(0, 1, this._state.partnerID);
      }
    }
    this.CV = new RevieveCV(this);
    this.AR = new RevieveAR(this);
    this.PR = new RevievePR(this);

    const swapModule = (name, newModule) => {
      this[name] = newModule;
    };

    this.unmountLiveARAfterInitialize = false;
    this.unmountLiveARInitializing = false;
    const liveARInitializer = {
      mounted: false,
      analyzeImage,
      scaleImage,
      initialize: async config => {
        this.unmountLiveARAfterInitialize = false;
        if (this.unmountLiveARInitializing) return null;
        this.unmountLiveARInitializing = true;
        return new Promise(resolve => {
          config.partnerID = this._state.partnerID;
          config.environment = this._state.environment;
          initializeLiveAR(config).then(newModule => {
            this.unmountLiveARInitializing = false;
            if (this.unmountLiveARAfterInitialize) {
              newModule.unmount();
              resolve(newModule);
            } else {
              const newModuleWithRelease = Object.assign(newModule, {
                mounted: true,
                analyzeImage,
                scaleImage,
                release: () => {
                  this.liveAR.unmount();
                  swapModule('liveAR', liveARInitializer);
                },
              });
              swapModule('liveAR', newModuleWithRelease);
              resolve(newModuleWithRelease);
            }
          });
        });
      },
      preload: async () => {
        return loadLibraryFiles();
      },
      release: () => {
        this.unmountLiveARAfterInitialize = true;
      },
    };

    this.liveAR = liveARInitializer;
  }

  /**
   * Returns the partnerID value in SDK
   *
   * @returns {String}
   */
  getPartnerId() {
    return this._state.partnerID;
  }

  /**
   * Sets the userID value in SDK
   */
  setUserId(userID) {
    this._state.userID = userID;
  }

  /**
   * Returns the userID value in SDK
   *
   * @returns {String}
   */
  getUserId() {
    return this._state.userID;
  }

  /**
   * Method to set the API version that will use the SDK
   * @param {String} apiVersion - Api version to use in SDK
   */
  setApiVersion(apiVersion) {
    if (apiVersionsAvailable.indexOf(apiVersion) >= 0) {
      this._state.apiVersion = apiVersion;
    } else {
      console.error('API version not supported');
      console.log('API versions available are: ' + JSON.stringify(apiVersionsAvailable));
    }
  }

  /**
   * Returns the API version configured to be used by the SDK
   *
   *  @returns {String} Current API version
   */
  getApiVersion() {
    return this._state.apiVersion;
  }

  /**
   * Returns the testing value in SDK
   *
   * @returns {Boolean}
   */
  isTesting() {
    return this._state.testing;
  }

  /**
   * Returns the environment value in SDK
   *
   * @returns {String}
   */
  getEnvironment() {
    return this._state.environment;
  }

  /**
   * Method to enable analytics trackers into SDK.
   * @param {Array} analyticsInfo - array with trackers' information that you want to enable in SDK.
   * Each element of the array must have a valid JSON object with the schema
   * specified in {@link Analytics.trackerInfoSchema}
   * @return {Boolean} true if trackers are set successfully.
   */
  setAnalytics(analyticsInfo) {
    // setTrackers does not exist in the Analytics class
    return this.analytics.setTrackers(analyticsInfo);
  }

  // ********************* CONFIGURATION METHODS *****************************************
  /**
   * Returns the current configuration object in SDK. If worstMetricsOptions is provided,
   * it will return only the worst metrics that are below the minThreshold.
   *
   * @param {Object} [worstMetricsOptions] - Object with minThreshold and limit to filter the worst metrics
   * @param {number} worstMetricsOptions.minThreshold - Minimum threshold for the worst metrics.
   * @param {number} worstMetricsOptions.limit - Limit of metrics to consider.
   * @returns {Object} JSON with all configuration values
   */
  getConfiguration(worstMetricsOptions) {
    if (!worstMetricsOptions) {
      return this._state.configuration;
    }

    const options = Object.assign({}, this._state.configuration);

    const {minThreshold, limit} = worstMetricsOptions;

    const metrics = Object.entries(options).filter(([key]) => key.endsWith('_cv'));
    const worstMetrics = metrics
        .filter(([, value]) => value < minThreshold)
        .sort(([, a], [, b]) => a - b)
        .slice(0, limit)
        .map(([key]) => key);

    metrics.forEach(([metric]) => {
      if (!worstMetrics.includes(metric)) {
        delete options[metric];
      }
    });

    return options;
  }

  /**
   * Returns a copy of the current configuration object in SDK
   *
   * @returns {Object} JSON with all configuration values
   */
  getSeralizableState() {
    return {
      configuration: Object.assign({}, this._state.configuration),
      cv: this.CV.getSeralizableState(),
      pr: this.PR.getSeralizableState(),
    };
  }

  /**
   * Change the configuration with the provided one
   *
   * @param {Object} state JSON with all configuration values
   */
  hydrateState(state) {
    this._state.configuration = Object.assign({}, state.configuration);
    this.CV.hydrateState(state.cv);
    this.PR.hydrateState(state.pr);
    this.CV.setFindings();
  }

  /**
   * Sets the configuration object to current state.
   * @param {Object} configuration - JSON object with the configuration to set in state
   * @return {Object} state configuration object after sets configuration.
   */
  setConfiguration(configuration) {
    if (!configuration) {
      return this._state.configuration;
    }
    this._state.configuration = Object.assign(this._state.configuration, configuration);
    return this._state.configuration;
  }

  /**
   * Method to get active variations in configuration
   */
  getVariations() {
    return this._state.configuration.variations;
  }

  /**
   * Metod to set the active variations in confifuration
   * @param {Array} variations - array with variations that you want to set
   */
  setVariations(variations) {
    this._state.configuration.variations = variations;
  }

  /**
   * Get an active variation by variationName parameter
   * @param {String} variantName - name of variation
   */
  getVariationByName(variantName) {
    if (!this._state.configuration.variations) {
      return null;
    }
    return this._state.configuration.variations.find(variation => variation.variantName === variantName) || null;
  }

  /**
   * Method to get active required tags in configuration
   */
  getRequiredTags() {
    return this._state.configuration.requiredTags;
  }

  /**
   * Method to set the active required tags in configuration
   * @param {Array} requiredTags - array with required tags to set in configuration
   * @param {string} variantName - The name of the variant to modify.
   */
  setRequiredTags(requiredTags, variantName = null) {
    if (variantName === null) {
      this._state.configuration.requiredTags = requiredTags;
    } else {
      this.addParameter('requiredTags', requiredTags, variantName);
    }
  }

  /**
   * Adds a parameter to the configuration state.
   * If a variant name is provided, adds the parameter to the variation
   * with that name. Otherwise, adds the parameter to the base configuration.
   *
   * @param {string} parameter - The name of the parameter to add.
   * @param {*} value - The value of the parameter to add.
   * @param {string} variantName - The name of the variant to modify.
   */
  addParameter(parameter, value, variantName) {
    if (variantName) {
      let variation = this.getVariationByName(variantName) || { variantName };
      if (!this._state.configuration.variations) {
        this._state.configuration.variations = [];
      }
      this._state.configuration.variations = [
        ...this._state.configuration.variations.filter(e => e.variantName !== variantName),
        variation,
      ];
      variation[parameter] = value;
    } else {
      this._state.configuration[parameter] = value;
    }
  }

  /**
   * Adds a required tag to the configuration
   * If a variant name is provided, adds the tag to the variation
   * with that name. Otherwise, adds the tag to the base configuration.
   * @param {string} tagName - The name of the tag to add.
   * @param {*} value - The value of the tag to add.
   * @param {string} variantName - The name of the variant to modify (optional).
   */
  addRequiredTag(tagName, value, variantName) {
    if (variantName) {
      let variation = this.getVariationByName(variantName) || { variantName };
      if (!variation.required_tags) {
        variation.required_tags = [];
      }

      variation.required_tags = [...variation.required_tags.filter(t => t !== tagName), tagName];
      if (variation.required_tags.length === 0) {
        variation.required_tags = null;
      }
    } else {
      if (!this._state.configuration.requiredTags) {
        this._state.configuration.requiredTags = [];
      }

      // in case of hair type, eye color, hair color, or skintone, we need to remove previous tag if it's present

      const removableTags = [
        Object.values(RevieveSDK.eyeColor).map(e => `${e}_eyes`),
        Object.values(RevieveSDK.skintone).map(e => `${e}_tone`),
        Object.values(RevieveSDK.hairColor).map(e => `${e}_hair`),
        Object.values(RevieveSDK.hairtype),
      ];

      for (let tags of removableTags) {
        if (tags.includes(tagName)) {
          this._state.configuration.requiredTags = this._state.configuration.requiredTags.filter(
            item => !tags.includes(item),
          );
        }
      }

      this._state.configuration.requiredTags = this._state.configuration.requiredTags.filter(e => e !== tagName);

      if (value) {
        this._state.configuration.requiredTags.push(tagName);
      }

      if (this._state.configuration.requiredTags.length === 0) {
        delete this._state.configuration.requiredTags;
      }
    }
  }

  /**
   * Sets the gender in configuration object
   *
   * @param {String} gender - valid gender from RevieveSDK.gender list
   */
  setGender(gender) {
    if (this.isCorrectInEnumeration('gender', gender)) {
      this._state.configuration.gender_ui = gender;
      this.analytics.sendEvent('RevieveSDK.setGender', gender);
    } else {
      console.error('Incorrect value for gender');
    }
  }

  /**
   * Sets the eye color in configuration object
   *
   * @param {String} eyeColor - valid eye color from RevieveSDK.eyeColor list
   * @param {Boolean=} requiredTag - Set true if you want only products with this feature
   * @param {String=} variantName - name of the variation to add this parameter
   */
  setEyeColor(eyeColor, requiredTag = false, variantName = null) {
    if (this.isCorrectInEnumeration('eyeColor', eyeColor)) {
      this.addParameter('eye_color_ui', eyeColor, variantName);
      if (requiredTag) {
        this.addRequiredTag(eyeColor + '_eyes', eyeColor, variantName);
      }
      this.analytics.sendEvent('RevieveSDK.setEyeColor', eyeColor);
    } else {
      console.error('Incorrect value for eyeColor');
    }
  }

  /**
   * Sets the hair color in configuration object
   *
   * @param {String} hairColor - valid hair color from RevieveSDK.hairColor list
   * @param {Boolean=} requiredTag - Set true if you want only products with this feature
   * @param {String=} variantName - name of the variation to add this parameter
   */
  setHairColor(hairColor, requiredTag = false, variantName = null) {
    if (this.isCorrectInEnumeration('hairColor', hairColor)) {
      this.addParameter('hair_color_ui', hairColor, variantName);
      if (requiredTag) {
        this.addRequiredTag(hairColor + '_hair', hairColor, variantName);
      }
      this.analytics.sendEvent('RevieveSDK.setHairColor', hairColor);
    } else {
      console.error('Incorrect value for hairColor');
    }
  }

  /**
   * Sets the hair type in configuration object
   *
   * @param {String} hairtype - valid hair type from RevieveSDK.hairtype list
   * @param {Boolean=} requiredTag - Set true if you want only products with this feature
   * @param {String=} variantName - name of the variation to add this parameter
   */
  setHairtype(hairtype, requiredTag = false, variantName = null) {
    if (isCorrectValue('hairtype', hairtype)) {
      this.addParameter('hairtype', hairtype, variantName);
      if (requiredTag) {
        this.addRequiredTag(RevieveSDK.hairtype[hairtype], hairtype, variantName);
      }
      this.analytics.sendEvent('RevieveSDK.setHairtype', hairtype);
    } else {
      console.error('Incorrect value for hair type');
    }
  }

  /**
   * Method to validate if a string is a valid in an enumeration
   * @param {String} enumeration
   * @param {String} value
   * @return {Boolean} true if it's a correct
   */
  isCorrectInEnumeration(enumeration, value) {
    return value === undefined || Object.values(RevieveSDK[enumeration]).indexOf(value) >= 0;
  }

  /**
   * Sets categories in configuration object
   *
   * @param {String[]} categories - array of strings with the list of categories to filter in PR
   * @param {String=} variantName - name of the variation to add this parameter
   */
  setCategories(categories, variantName = null) {
    this.addParameter('categories', categories, variantName);
    this.analytics.sendEvent('RevieveSDK.setCategories', categories);
  }

  /**
   * Method to configure categories that will match the color skintone parameter
   * during the product matching process
   * @param {String[]} categories array of strings with the list of categories
   * @param {String=} variantName - name of the variation to add this parameter
   */
  setMatchToSkintoneCategories(categories, variantName = null) {
    this.addParameter('match_to_skintone', categories, variantName);
    this.analytics.sendEvent('RevieveSDK.setMatchToSkintoneCategories', categories);
  }

  /**
   * Sets age in configuration object
   *
   * @param {integer} age - Year of birth
   */
  setAge(age) {
    this._state.configuration.age_ui = age;
    this.analytics.sendEvent('RevieveSDK.setAge', age);
  }

  /**
   * Sets lattitude in configuration object
   *
   * @param {float} lat - lattitude of user location
   */
  setLat(lat) {
    this._state.configuration.lat = lat;
    this.analytics.sendEvent('RevieveSDK.setLat', lat);
  }

  /**
   * Sets longitude in configuration object
   *
   * @param {float} lat - longitude of user location
   */
  setLon(lon) {
    this._state.configuration.lon = lon;
    this.analytics.sendEvent('RevieveSDK.setLon', lon);
  }

  /**
   * Sets numeric cv metric between a range to filter products in PR module.
   *
   * @param {string} metric - Metric name
   * @param {number} value - Metric value
   * @param {number=} min - Minimum allowed value
   * @param {number=} max - Maximum allowed value
   */
  setCVFloatValue(metric, value, min = 0, max = 1) {
    if (!metric || typeof value !== 'number') return;
    if (value < min || value > max) {
      console.error('Incorrect value for ' + metric);
      return;
    }

    this._state.configuration[metric + '_cv'] = value;
  }

  /**
   * Sets cv metric from allowed options to filter products in PR module.
   *
   * @param {string} metric - Metric name
   * @param {number|string} value - Metric value
   */
  setCVEnumValue(metric, value, allowedValues = []) {
    if (!metric || value == null) return;
    if (allowedValues.indexOf(value) === -1) {
      console.error('Incorrect value for ' + metric);
      return;
    }

    this._state.configuration[metric + '_cv'] = value;
  }

  /**
   * Sets wrinkles value to filter products in PR module.
   *
   * @param {float} wrinkles - How prominent wrinkles user has in their opinion between 0.0 - 1.0.
   * @param {String=} origin - you can specify the origin of the value, 'ui' to user opinion
   * or 'cv' to computer vision value. Default is 'ui'
   * @param {String=} variantName - name of the variation to add this parameter
   */
  setWrinkles(wrinkles, origin = 'ui', variantName = null) {
    if (isCorrectValue('wrinkles', wrinkles)) {
      this.addParameter('wrinkles_' + (origin || 'ui'), wrinkles, variantName);
      if (origin === 'ui') {
        this.analytics.sendEvent('RevieveSDK.setWrinkles', wrinkles);
      }
    } else {
      console.error('Incorrect value for wrinkles');
    }
  }

  /**
   * Sets eyebags value to filter products in PR module.
   *
   * @param {float} eyebags - How prominent eyebags user has in their opinion between 0.0 - 1.0.
   * @param {String=} origin - you can specify the origin of the value, 'ui' to user opinion
   * or 'cv' to computer vision value. Default is 'ui'
   * @param {String=} variantName - name of the variation to add this parameter
   */
  setEyebags(eyebags, origin = 'ui', variantName = null) {
    if (isCorrectValue('eyebags', eyebags)) {
      this.addParameter('eyebags_' + (origin || 'ui'), eyebags, variantName);
      if (origin === 'ui') {
        this.analytics.sendEvent('RevieveSDK.setEyebags', eyebags);
      }
    } else {
      console.error('Incorrect value for eyebags');
    }
  }

  /**
   * Sets redness value to filter products in PR module.
   *
   * @param {float} redness - How prominent redness user has in their opinion between 0.0 - 1.0.
   * @param {String=} variantName - name of the variation to add this parameter
   */
  setRedness(redness, variantName = null) {
    if (isCorrectValue('redness', redness)) {
      this.addParameter('redness_cv', redness, variantName);
    } else {
      console.error('Incorrect value for redness');
    }
  }

  /**
   * Sets darkSpots value to filter products in PR module.
   *
   * @param {float} darkSpots - How prominent darkSpots user has in their opinion between 0.0 - 1.0.
   * @param {String=} variantName - name of the variation to add this parameter
   */
  setDarkSpots(darkSpots, variantName = null) {
    if (isCorrectValue('darkSpots', darkSpots)) {
      this.addParameter('dark_spots_ui', darkSpots, variantName);
      this.analytics.sendEvent('RevieveSDK.setDarkSpots', darkSpots);
    } else {
      console.error('Incorrect value for dark_spots');
    }
  }

  /**
   * Sets skintone value.
   *
   * @param {float} skintone - Skintone value between 1 and 6 in Fitzpatrick scale.
   * If you want to set an undefined value to force the autodetection by CV module,
   * you must set this value to 0.
   * @param {Boolean=} requiredTag - Set true if you want only products with this feature
   * @param {String=} variantName - name of the variation to add this parameter
   * @param {Boolean=} withAnalyzeImage - You can set to false if you wish
   * to set skintone without CV analyze image.
   * @return {Promise} promise that will be resolve or rejected by analyze image process
   */
  setSkintone(skintone, requiredTag = false, variantName = null, withAnalyzeImage = true) {
    return new Promise((resolve, reject) => {
      if (isCorrectValue('skintone', skintone)) {
        this.addParameter('skintone', skintone, variantName);
        if (requiredTag) {
          this.addRequiredTag(RevieveSDK.skintone[skintone] + '_tone', skintone, variantName);
        }
        this.analytics.sendEvent('RevieveSDK.setSkintone', skintone);
        if (skintone >= 1 && withAnalyzeImage) {
          return this.CV.analyzeImage();
        }
        resolve();
      } else {
        console.error('Incorrect value for skintone');
        reject();
      }
      return true;
    });
  }

  /** Method to get the skintone value configured
   * @return {float} skintone value in Fitzpatrick scale.
   */
  getSkintone() {
    return this._state.configuration.skintone;
  }

  /**
   * Sets skintone color value to filter products in PR module. Also detects and sets
   * the skintone value in Fitzpatrick scale derived from this skintone color.
   *
   * @param {String} color - skintone hexadecimal color
   * @param {String} origin - you can specify the origin of the value, 'ui' to user
   * opinion 'cv' to computer vision value. Default is 'cv'
   * @param {String} requiredTag - Set true if you want only products with this feature
   * @param {String} variantName - name of the variation to add this parameter
   */
  setSkintoneColor(skintoneColor, origin = 'cv', variantName = null) {
    if (origin === 'cv') {
      this.addParameter('skintone_color_cv', skintoneColor, variantName);

      if (skintoneColor === undefined) return;
      this.AR._setSkintoneColor(skintoneColor);

      let skintone = this.CV.detectSkintoneFromSkinColor(skintoneColor);
      if (skintone) {
        this.setSkintone(parseInt(skintone, 10), false, null, false);
      }
      this.analytics.sendEvent('RevieveSDK.setSkintoneColor', skintoneColor);
    } else {
      this.addParameter('skintone_color_ui', skintoneColor, variantName);
    }
  }

  /** Method to clear the values of all ui variables in configuration object
   */
  clearUIConfiguration() {
    Object.keys(this._state.configuration).forEach(variable => {
      if (variable.endsWith('_ui')) delete this._state.configuration[variable];
    });
  }

  /**
   * Sets cuperose value to filter products in PR module.
   *
   * @param {boolean} cuperose - If the user has skin cornern "cuperose", set this to true.
   * @param {Boolean=} requiredTag - Set true if you want only products with this feature
   * @param {String=} variantName - name of the variation to add this parameter
   */
  setCuperose(cuperose, requiredTag = false, variantName = null) {
    this.addParameter('cuperose_ui', cuperose ? 1 : 0, variantName);
    if (requiredTag) {
      this.addRequiredTag('cuperose', cuperose, variantName);
    }
    this.analytics.sendEvent('RevieveSDK.setCuperose', cuperose);
  }

  /**
   * Sets rocasea value to filter products in PR module.
   *
   * @param {boolean} rocasea - If the user has skin cornern "rocasea", set this to true.
   * @param {Boolean=} requiredTag - Set true if you want only products with this feature
   * @param {String=} variantName - name of the variation to add this parameter
   */
  setRocasea(rocasea, requiredTag = false, variantName = null) {
    this.addParameter('rocasea_ui', rocasea ? 1 : 0, variantName);
    if (requiredTag) {
      this.addRequiredTag('rocasea', rocasea, variantName);
    }
    this.analytics.sendEvent('RevieveSDK.setRocasea', rocasea);
  }

  /**
   * Sets acne value to filter products in PR module.
   *
   * @param {boolean} acne - If the user has skin cornern "acne", set this to true.
   * @param {Boolean=} requiredTag - Set true if you want only products with this feature
   * @param {String=} variantName - name of the variation to add this parameter
   */
  setAcne(acne, requiredTag = false, variantName = null) {
    this.addParameter('acne_ui', acne ? 1 : 0, variantName);
    if (requiredTag) {
      this.addRequiredTag('acne', acne, variantName);
    }
    this.analytics.sendEvent('RevieveSDK.setAcne', acne);
  }

  /**
   * Sets eczema value to filter products in PR module.
   *
   * @param {boolean} eczema - If the user has skin cornern "eczema", set this to true.
   * @param {Boolean=} requiredTag - Set true if you want only products with this feature
   * @param {String=} variantName - name of the variation to add this parameter
   */
  setEczema(eczema, requiredTag = false, variantName = null) {
    this.addParameter('eczema_ui', eczema ? 1 : 0, variantName);
    if (requiredTag) {
      this.addRequiredTag('eczema', eczema, variantName);
    }
    this.analytics.sendEvent('RevieveSDK.setEczema', eczema);
  }

  /**
   * Sets hyperpigmentation value to filter products in PR module.
   *
   * @param {boolean} hyperpigmentation - If the user has skin
   * cornern "hyperpigmentation", set this to true.
   * @param {Boolean=} requiredTag - Set true if you want only products with this feature
   * @param {String=} variantName - name of the variation to add this parameter
   */

  setHyperpigmentation(hyperpigmentation, requiredTag = false, variantName = null) {
    this.addParameter('hyperpigmentation_ui', hyperpigmentation ? 1 : 0, variantName);
    if (requiredTag) {
      this.addRequiredTag('hyperpigmentation', hyperpigmentation, variantName);
    }
    this.analytics.sendEvent('RevieveSDK.setHyperpigmentation', hyperpigmentation);
  }

  /**
   * Sets oilyness value to filter products in PR module.
   *
   * @param {boolean} oilyness - If the user has skin cornern "oilyness", set this to true.
   * @param {Boolean=} requiredTag - Set true if you want only products with this feature
   * @param {String=} variantName - name of the variation to add this parameter
   */
  setOilyness(oilyness, requiredTag = false, variantName = null) {
    this.addParameter('oilyness_ui', oilyness ? 1 : 0, variantName);
    if (requiredTag) {
      this.addRequiredTag('oilyness', oilyness, variantName);
    }
    this.analytics.sendEvent('RevieveSDK.setOilyness', oilyness);
  }

  /**
   * Sets dullTired value to filter products in PR module.
   *
   * @param {boolean} dullTired - If the user has skin cornern "dull and tired", set this to true.
   * @param {Boolean=} requiredTag - Set true if you want only products with this feature
   * @param {String=} variantName - name of the variation to add this parameter
   */
  setDullTired(dullTired, requiredTag = false, variantName = null) {
    this.addParameter('dull_tired_ui', dullTired ? 1 : 0, variantName);
    if (requiredTag) {
      this.addRequiredTag('dull_tired', dullTired, variantName);
    }
    this.analytics.sendEvent('RevieveSDK.setDullTired', dullTired);
  }

  /**
   * Sets psoriasis value to filter products in PR module.
   *
   * @param {boolean} psoriasis - If the user has skin cornern "psoriasis", set this to true.
   * @param {Boolean=} requiredTag - Set true if you want only products with this feature
   * @param {String=} variantName - name of the variation to add this parameter
   */
  setPsoriasis(psoriasis, requiredTag = false, variantName = null) {
    this.addParameter('psoriasis_ui', psoriasis ? 1 : 0, variantName);
    if (requiredTag) {
      this.addRequiredTag('psoriasis', psoriasis, variantName);
    }
    this.analytics.sendEvent('RevieveSDK.setPsoriasis', psoriasis);
  }

  /**
   * Sets organic value to filter products in PR module.
   *
   * @param {boolean} organic - If user prefers organic products, set to true.
   * @param {Boolean=} requiredTag - Set true if you want only products with this feature
   * @param {String=} variantName - name of the variation to add this parameter
   */
  setOrganic(organic, requiredTag = false, variantName = null) {
    this.addParameter('organic_ui', organic ? 1 : 0, variantName);
    if (requiredTag) {
      this.addRequiredTag('organic', organic, variantName);
    }
    this.analytics.sendEvent('RevieveSDK.setOrganic', organic);
  }

  /**
   * Sets antiAge value to filter products in PR module.
   *
   * @param {boolean} antiAge - If user prefers antiaging products, set to true.
   * @param {Boolean=} requiredTag - Set true if you want only products with this feature
   * @param {String=} variantName - name of the variation to add this parameter
   */
  setAntiAge(antiAge, requiredTag = false, variantName = null) {
    this.addParameter('anti_age_ui', antiAge ? 1 : 0, variantName);
    if (requiredTag) {
      this.addRequiredTag('anti_age', antiAge, variantName);
    }
    this.analytics.sendEvent('RevieveSDK.setAntiAge', antiAge);
  }

  /**
   * Sets fragranceFree value to filter products in PR module.
   *
   * @param {boolean} fragranceFree - If user prefers fragrance free products, set to true.
   * @param {Boolean=} requiredTag - Set true if you want only products with this feature
   * @param {String=} variantName - name of the variation to add this parameter
   */
  setFragranceFree(fragranceFree, requiredTag = false, variantName = null) {
    this.addParameter('fragrance_free_ui', fragranceFree ? 1 : 0, variantName);
    if (requiredTag) {
      this.addRequiredTag('fragrance_free', fragranceFree, variantName);
    }
    this.analytics.sendEvent('RevieveSDK.setFragranceFree', fragranceFree);
  }

  /**
   * Sets parabenFree value to filter products in PR module.
   *
   * @param {boolean} parabenFree - If user prefers paraben free products, set to true.
   * @param {Boolean=} requiredTag - Set true if you want only products with this feature
   * @param {String=} variantName - name of the variation to add this parameter
   */
  setParabenFree(parabenFree, requiredTag = false, variantName = null) {
    this.addParameter('paraben_free_ui', parabenFree ? 1 : 0, variantName);
    if (requiredTag) {
      this.addRequiredTag('paraben_free', parabenFree, variantName);
    }
    this.analytics.sendEvent('RevieveSDK.setParabenFree', parabenFree);
  }

  /**
   * Sets sunProtection value to filter products in PR module.
   *
   * @param {boolean} sunProtection - If user prefers sun protection products, set to true.
   * @param {Boolean=} requiredTag - Set true if you want only products with this feature
   * @param {String=} variantName - name of the variation to add this parameter
   */
  setSunProtection(sunProtection, requiredTag = false, variantName = null) {
    this.addParameter('sun_protection_ui', sunProtection ? 1 : 0, variantName);
    if (requiredTag) {
      this.addRequiredTag('sun_protection', sunProtection, variantName);
    }
    this.analytics.sendEvent('RevieveSDK.setSunProtection', sunProtection);
  }

  /**
   * Sets skinBrightening value to filter products in PR module.
   *
   * @param {boolean} skinBrightening - If user prefers skin brightening products, set to true.
   * @param {Boolean=} requiredTag - Set true if you want only products with this feature
   * @param {String=} variantName - name of the variation to add this parameter
   */
  setSkinBrightening(skinBrightening, requiredTag = false, variantName = null) {
    this.addParameter('skin_brightening_ui', skinBrightening ? 1 : 0, variantName);
    if (requiredTag) {
      this.addRequiredTag('skin_brightening', skinBrightening, variantName);
    }
    this.analytics.sendEvent('RevieveSDK.setSkinBrightening', skinBrightening);
  }

  /**
   * Sets skinFirming value to filter products in PR module.
   *
   * @param {boolean} skinFirming - If user prefers skin firming products, set to true.
   * @param {Boolean=} requiredTag - Set true if you want only products with this feature
   * @param {String=} variantName - name of the variation to add this parameter
   */
  setSkinFirming(skinFirming, requiredTag = false, variantName = null) {
    this.addParameter('skin_firming_ui', skinFirming ? 1 : 0, variantName);
    if (requiredTag) {
      this.addRequiredTag('skin_firming', skinFirming, variantName);
    }
    this.analytics.sendEvent('RevieveSDK.setSkinFirming', skinFirming);
  }

  /**
   * Sets custom attribute value to filter products in PR module.
   *
   * @param {String} customAttribute - Name of the custom attribute
   * @param {String|number|boolean|object} value - If you want to filter
   * by this custom attribute, set a value.
   * @param {Boolean=} requiredTag - Set true if you want only products with this attribute
   * @param {String=} variantName - name of the variation to add this parameter
   */
  setCustomAttribute(customAttribute, value, requiredTag = false, variantName = null) {
    this.addParameter(customAttribute, value, variantName);
    if (requiredTag) {
      this.addRequiredTag(customAttribute, value, variantName);
    }
    this.analytics.sendEvent('RevieveSDK.setCustomAttribute', customAttribute + '_' + value);
  }

  /**
   * Sets the skin type in configuration object
   *
   * @param {String} skintype - valid skin type from RevieveSDK.skinTypes list
   * @param {String=} variantName - name of the variation to add this parameter
   */
  setSkintype(skintype, variantName = null) {
    Object.values(RevieveSDK.skinTypes).forEach(st => {
      const skintypeVariable = `${st}_skin_ui`;
      this.addParameter(skintypeVariable, skintypeVariable === `${skintype}_skin_ui` ? 1 : 0, variantName);
    });
    this.analytics.sendEvent('RevieveSDK.setSkintype', skintype);
  }

  /**
   * Sets the locale in configuration object
   *
   * @param {String} locale - selected locale of the user
   */
  setLocale(locale) {
    this._state.configuration.locale = locale;
    this.analytics.sendEvent('RevieveSDK.setLocale', locale);
  }

  /**
   * Gets the current state of the CORE
   *
   * @returns {Object}
   */
  getState() {
    return this._state;
  }

  /**
   * Gets a new CV module instance
   *
   * @returns {Object}
   */
  getNewCV() {
    return new RevieveCV(this);
  }
}

const correctValues = {
  wrinkles: { min: 0, max: 1 },
  eyebags: { min: 0, max: 1 },
  redness: { min: 0, max: 1 },
  darkSpots: { min: 0, max: 1 },
  skintone: { min: 0, max: 6 },
  hairtype: { min: 0, max: 4 },
};

function isCorrectValue(field, value) {
  if (value === undefined) return true;
  let valueLimits = correctValues[field];
  if (!valueLimits) return false;
  return value >= valueLimits.min && value <= valueLimits.max;
}

/**
 * List of gender strings accepted by SDK
 */
RevieveSDK.gender = {
  FEMALE: 'female',
  MALE: 'male',
};

/**
 * List of skin types accepted by SDK
 */
RevieveSDK.skinTypes = {
  NORMAL: 'normal',
  DRY: 'dry',
  COMBINATION: 'combination',
  OILY: 'oily',
  SENSITIVE: 'sensitive',
};

/**
 * List of eye colors accepted by SDK
 */
RevieveSDK.eyeColor = {
  BLUE: 'blue',
  BROWN: 'brown',
  GREEN: 'green',
  HAZEL: 'hazel',
};

/**
 * List of hair colors accepted by SDK
 */
RevieveSDK.hairColor = {
  BLACK: 'black',
  BLONDE: 'blonde',
  BROWN: 'brown',
  RED: 'red',
  SILVER: 'silver',
};

RevieveSDK.skintone = {
  0: '',
  1: 'light',
  2: 'fair',
  3: 'medium',
  4: 'olive',
  5: 'tan',
  6: 'dark',
};

RevieveSDK.hairtype = {
  0: 'coily_hair',
  1: 'cornroll_hair',
  2: 'curly_hair',
  3: 'straight_hair',
  4: 'wavy_hair',
};

/**
 * List of available masks for the API version selected
 */
RevieveSDK.masks = RevieveAR.masks[getApiVersion(false)];

/**
 * Default strenght value applied in AR effects.
 */
RevieveSDK.defaultStrength = RevieveAR.defaultStrength;

/**
 * Values of different Makeup possible measures
 */
RevieveSDK.makeupMeasures = RevieveAR.makeupMeasures;

/**
 * Catalog of effects available in AR module
 */
RevieveSDK.effectsCatalog = Object.keys(RevieveAR.effectBundles).map(effectKey => ({
  method: effectKey,
  type: RevieveAR.effectBundles[effectKey].type,
  description: RevieveAR.effectBundles[effectKey].description,
  parameters: RevieveAR.effectBundles[effectKey].parameters,
}));

RevieveSDK.PIXI = PIXI;

/**
 * Analytics providers available
 */
RevieveSDK.analyticsProvidersAvailable = Analytics.providersAvailable;

window.RevieveSDK = RevieveSDK;

export default RevieveSDK;
