import { highlightColor } from '../appConf';

export function draw(canvas, mask, imageBase64 = null, fillColor = null, useStroke = false, individualFindingsInfo) {
  return new Promise((resolve, reject) => {
    if (!canvas || !mask) {
      console.error('Draw function: canvas and mask are mandatory');
      reject();
    }
    let acontext = canvas.getContext('2d');
    if (individualFindingsInfo && individualFindingsInfo.length > 0) {
      const pointRadius = Math.min(canvas.width, canvas.height) * 0.008;
      individualFindingsInfo.forEach(individualFinding => {
        if (individualFinding.type === 'point') {
          drawCircle(
            canvas,
            individualFinding.params[0],
            individualFinding.params[1],
            pointRadius,
            fillColor,
            null,
            false,
          );
        }
      });
    } else {
      let shapes = mask.shapes;
      if (mask.mask && mask.name !== 'Skin') {
        mask.mask.color = 2;
        shapes.push(mask.mask);
      }
      let lineWidth = canvas.width <= 700 || canvas.height <= 700 ? 1.5 : 3;
      drawShapes(acontext, shapes, fillColor, useStroke, lineWidth);
    }
    if (imageBase64) {
      let imgObject = document.createElement('img');
      imgObject.src = imageBase64;
      imgObject.onload = function() {
        addImageTexture(acontext, imgObject, individualFindingsInfo);
        resolve();
      };
    } else {
      resolve();
    }
  });
}

function addImageTexture(ctx, img) {
  let globalCompositeOriginal = ctx.globalCompositeOperation;
  ctx.globalCompositeOperation = 'source-in';
  ctx.drawImage(img, 0, 0);
  ctx.globalCompositeOperation = globalCompositeOriginal;
}

function drawShapes(acontext, shapes, fillColor = null, useStroke = false, lineWidth) {
  for (let shape of shapes) {
    if (useStroke && shape.color === 0) {
      continue;
    }
    acontext.save();
    let params = shape.params.slice(0); // copy arr
    if (shape.type === 'ellipse') {
      drawEllipse(acontext, params, shape.color, fillColor, useStroke);
    }
    if (shape.type === 'polygon' || shape.type === 'unrestricted_polygon') {
      drawPolygon(
        acontext,
        params,
        shape.color,
        useStroke
          ? { stroke: { color: fillColor || 'rgb(44,192,205)', width: lineWidth } }
          : { fill: { color: fillColor || 'rgb(255,255,255)' } },
      );
    }
    if (shape.type === 'polyline') {
      drawLine(acontext, params, fillColor, useStroke, lineWidth);
    }
    acontext.restore();
  }
  acontext.save();
}

export function drawPolygon(ctx, params, color, configuration) {
  let globalCompositeOriginal = ctx.globalCompositeOperation;
  if (configuration) {
    if (configuration.fill && configuration.fill.color) {
      ctx.fillStyle = configuration.fill.color;
    }
    if (configuration.stroke) {
      const { color: strokeColor, width, lineDash } = configuration.stroke;
      if (strokeColor) ctx.strokeStyle = strokeColor;
      if (width) ctx.lineWidth = width;
      if (lineDash) ctx.setLineDash(lineDash);
      ctx.lineCap = 'round';
      ctx.lineJoin = 'round';
    }
  }
  if (color === 0) {
    ctx.globalCompositeOperation = 'destination-out';
    ctx.fillStyle = 'rgb(255, 255, 255)';
  }
  if (color === 2) {
    ctx.globalCompositeOperation = 'destination-in';
    ctx.fillStyle = 'rgb(255, 255, 255)';
  }

  ctx.beginPath();

  let start = params.shift();
  ctx.moveTo(start[0], start[1]);

  for (let coords of params) {
    ctx.lineTo(coords[0], coords[1]);
  }

  ctx.lineTo(start[0], start[1]);
  ctx.closePath();

  if (configuration) {
    if (configuration.stroke) ctx.stroke();
    if (configuration.fill) ctx.fill();
  }
  ctx.globalCompositeOperation = globalCompositeOriginal;
}

export function drawEllipse(ctx, params, color, fillColor, useStroke) {
  if (params.length !== 7) {
    console.error('incorrect param length for ellipse');
    console.log(params);
  }
  ctx.fillStyle = fillColor || 'rgb(255, 255, 255)';
  let globalCompositeOriginal = ctx.globalCompositeOperation;
  if (color === 0) {
    ctx.globalCompositeOperation = 'destination-out';
    ctx.fillStyle = 'rgb(255, 255, 255)';
  }
  if (color === 2) {
    ctx.globalCompositeOperation = 'destination-in';
    ctx.fillStyle = 'rgb(255, 255, 255)';
  }
  if (useStroke) {
    ctx.strokeStyle = fillColor || 'rgb(44,192,205)';
    ctx.lineCap = 'round';
    ctx.lineWidth = 15;
  }
  ctx.beginPath();
  ctx.ellipse(
    params[0],
    params[1],
    params[2],
    params[3],
    (params[4] * Math.PI) / 180,
    (params[5] * Math.PI) / 180,
    (params[6] * Math.PI) / 180,
  );
  ctx.closePath();

  if (useStroke) {
    ctx.stroke();
  } else {
    ctx.fill();
  }

  ctx.globalCompositeOperation = globalCompositeOriginal;
}

export function drawLine(ctx, params, strokeColor, isHighlight, lineWidth) {
  ctx.strokeStyle = strokeColor || (isHighlight ? 'rgb(44,192,205)' : 'rgb(0, 0, 0)');
  ctx.lineCap = 'round';
  ctx.lineWidth = lineWidth;
  if (isHighlight) {
    ctx.lineWidth = 15;
  }
  ctx.beginPath();

  let start = params.shift();
  ctx.moveTo(start[0], start[1]);

  for (let coords of params) {
    ctx.lineTo(coords[0], coords[1]);
  }

  ctx.stroke();
}

export function drawPoint(ctx, params, color, size = 5) {
  ctx.fillStyle = color || 'rgb(0, 0, 0)';
  ctx.fillRect(params[0], params[1], size, size);
}

export function highlightArea(canvas, fillColor) {
  if (!canvas) {
    console.error('Draw function: canvas and mask are mandatory');
    return;
  }
  let ctx = canvas.getContext('2d');
  let globalCompositeOriginal = ctx.globalCompositeOperation;
  ctx.globalCompositeOperation = 'source-out';
  ctx.fillStyle = fillColor || highlightColor;
  ctx.beginPath();
  ctx.rect(0, 0, canvas.width, canvas.height);
  ctx.closePath();
  ctx.fill();
  ctx.globalCompositeOperation = globalCompositeOriginal;
}

export function drawCircle(canvas, x, y, radius, fillColor, strokeColor, withShadow, lineWidth = 2) {
  let context = canvas.getContext('2d');
  context.beginPath();
  context.arc(x, y, radius, 0, 2 * Math.PI, false);
  context.fillStyle = fillColor;
  if (withShadow) {
    context.shadowColor = '#111';
    context.shadowBlur = 20;
    context.shadowOffsetX = 0;
    context.shadowOffsetY = 5;
  }
  context.fill();
  if (strokeColor) {
    context.lineWidth = lineWidth;
    context.strokeStyle = strokeColor;
    context.stroke();
  }
}
