import 'whatwg-fetch';

export function checkStatus(requestResponse) {
  if (requestResponse.status >= 200 && requestResponse.status < 300) {
    return requestResponse;
  } else {
    let error = new Error(requestResponse.statusText);
    error.requestResponse = requestResponse;
    error.code = requestResponse.status;
    throw error;
  }
}

const fetchWithTimeoutPromise = (url, options, timeout) => {
  return new Promise(function(resolve, reject) {
    fetch(url, options).then(resolve, reject);
    if (timeout) {
      setTimeout(() => {
        const error = new Error('Response timed out');
        error.code = 504;
        reject(error);
      }, timeout);
    }
  });
};

const fetchWithTimeoutAbort = (url, options, timeout) => {
  const controller = new AbortController();
  if (timeout) {
    Object.assign(options, { signal: controller.signal });
  }
  const timer =
    timeout &&
    setTimeout(() => {
      controller.abort();
    }, timeout);

  return fetch(url, options)
    .then(response => response)
    .catch(error => {
      if (error.name === 'AbortError') {
        const extendedError = new Error('Response timed out');
        extendedError.code = 504;
        throw extendedError;
      }

      throw error;
    })
    .finally(() => {
      if (timer) {
        clearTimeout(timer);
      }
    });
};

const supportsAbort =
  window.AbortController && typeof window.Request === 'function' && 'signal' in window.Request.prototype;

export const fetchWithTimeout = supportsAbort ? fetchWithTimeoutAbort : fetchWithTimeoutPromise;
