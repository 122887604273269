import { getServerUrl } from '../appConf';
import { checkStatus } from '../utils/fetch';

export function addToCart({ historyId, purchases, testing = false, environment = 'test' }) {
  let serverUrl = getServerUrl(testing, environment);
  let url = serverUrl + 'api/functions/addToCart';
  let payload = {
    history_id: historyId,
    purchases,
  };

  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
      'X-Parse-Application-Id': 'Revieve API',
    },
  })
    .then(checkStatus)
    .then(response => response.json())
    .catch(error => {
      console.error('Request failed', error);
      throw error;
    });
}
