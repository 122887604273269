function getReferencePoint({ faceArea, areaName }) {
  const { bounding_box: boundingBox } = faceArea;
  const faceUnitX = parseInt((boundingBox[2] - boundingBox[0]) / 10, 10);
  const faceUnitY = parseInt((boundingBox[3] - boundingBox[1]) / 10, 10);
  switch (areaName) {
    case 'forehead':
      return {
        x: 0,
        y: 3 * faceUnitY,
      };
    case 'betweenEyes':
      return {
        x: 0,
        y: faceUnitY,
      };
    case 'leftEye':
      return {
        x: 3 * faceUnitX,
        y: 0,
      };
    case 'rightEye':
      return {
        x: -3 * faceUnitX,
        y: 0,
      };
    case 'leftCheek':
      return {
        x: 3 * faceUnitX,
        y: -2 * faceUnitY,
      };
    case 'rightCheek':
      return {
        x: -3 * faceUnitX,
        y: -2 * faceUnitY,
      };
    case 'chin':
      return {
        x: 0,
        y: -4 * faceUnitY,
      };
    case 'lips':
      return {
        x: 0,
        y: -2 * faceUnitY,
      };
    case 'leftLips':
      return {
        x: 2 * faceUnitX,
        y: -2 * faceUnitY,
      };
    case 'rightLips':
      return {
        x: -2 * faceUnitX,
        y: -2 * faceUnitY,
      };
    default:
      return {
        x: 0,
        y: 0,
      };
  }
}

function getAnimationData({ canvas, faceArea, areaName, ratio, scaleFactor }) {
  // DATA TO MOVE THE CENTER OF THE FACE TO THE CENTER OF THE CANVAS
  const faceMidpointX = faceArea.bounding_box[0] + (faceArea.bounding_box[2] - faceArea.bounding_box[0]) / 2;
  const faceMidpointY = faceArea.bounding_box[1] + (faceArea.bounding_box[3] - faceArea.bounding_box[1]) / 2;
  const canvasMidpointX = canvas.width / 2;
  const canvasMidpointY = canvas.height / 2;
  const translateFaceToCenterX = canvasMidpointX - faceMidpointX;
  const translateFaceToCenterY = canvasMidpointY - faceMidpointY;

  // DATA TO SCALE
  const ratioFullWidth = canvas.width / (faceArea.bounding_box[2] - faceArea.bounding_box[0]);
  const ratioFullHeight = canvas.height / (faceArea.bounding_box[3] - faceArea.bounding_box[1]);
  // We avoid scale less than 1.
  // we choose the minimun ratio to ensure whole face is allways on the screen
  const isAFullFaceAreaName = areaName === 'skin' || areaName === 'face' || areaName === 'faceShape';
  const scaleToFace = Math.min(ratioFullWidth, ratioFullHeight);
  const scaleBaseFactor = areaName === 'forehead' ? 1.4 : 1.5;
  const scaleAreaFactor = isAFullFaceAreaName ? 1 : scaleFactor * scaleBaseFactor;
  const scale = Math.max(scaleToFace * scaleAreaFactor, 1);

  // HOW MUCH SHOULD I MOVE TO FOCUSE THE AREA?
  const referencePoint = getReferencePoint({ faceArea, areaName });

  const animationData = {
    x: (translateFaceToCenterX + referencePoint.x) * ratio,
    y: (translateFaceToCenterY + referencePoint.y) * ratio,
    rotation: faceArea.rotation,
    scale,
  };
  return animationData;
}

export function getAnimationStyle(canvas, faceArea, ratio, areaName, scaleFactor) {
  if (!canvas) {
    return null;
  }
  let animationData = getAnimationData({ canvas, faceArea, areaName, ratio, scaleFactor });
  if (!animationData) {
    return null;
  }
  return {
    in: {
      transform: `rotate(${-animationData.rotation}deg) scale(${animationData.scale}) translate3d(${
        animationData.x
      }px, ${animationData.y}px, 0px)`,
      transformOrigin: 'center center',
      transition: 'all 0.8s ease-in-out',
    },
    out: {
      transform: 'rotate(0deg) scale(1) translate3d(0px, 0px, 0px)',
      transformOrigin: 'center center',
      transition: 'all 0.8s ease-in-out',
    },
  };
}

export function convertMaskToArea(masks) {
  if (!masks || masks.length <= 0) {
    return null;
  }
  let mask = masks[0];
  return maskToAreaMapping[mask];
}

let maskToAreaMapping = {
  Skin: 'skin',
  forehead: 'forehead',
  'forehead worry lines': 'forehead',
  'between-brows frown lines': 'betweenEyes',
  'noseline bunny lines': 'betweenEyes',
  'left side eye crow\'s feet': 'leftEye',
  'right side eye crow\'s feet': 'rightEye',
  'left side tearline': 'leftEye',
  'right side tearline': 'rightEye',
  'left eye': 'leftEye',
  'right eye': 'rightEye',
  Eyebrows: 'forehead',
  'left cheek': 'leftCheek',
  'right cheek': 'rightCheek',
  Lips: 'lips',
  'left side around the eye': 'leftEye',
  'right side around the eye': 'rightEye',
  'left under-the-eye region': 'leftEye',
  'right under-the-eye region': 'rightEye',
  nose: 'nose',
  'left side around mouth': 'leftCheek',
  'right side around mouth': 'rightCheek',
  chin: 'chin',
  'left side nasolabial line': 'leftLips',
  'right side nasolabial line': 'rightLips',
  'left side marionette line': 'leftLips',
  'right side marionette line': 'rightLips',
  'vertical lip lines': 'lips',
  'right lower face': 'rightLips',
  'left lower face': 'leftLips',
};
